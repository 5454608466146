/* eslint eqeqeq: "off" */
import React, { useState, useEffect } from "react"
import { withAuthenticator } from "@aws-amplify/ui-react"
import Layout from "../ui/Layout"
import QRCode from "qrcode.react"
import { fromOrderIdToUrl, createOrderId } from "../util/OrderUtil"
import {
  Button,
  Label,
} from "semantic-ui-react"
import Color from "../style/Color"

const QR_SIZE = 256;

const getCanvasUrl = () => {
  const canvases = document.getElementsByTagName("canvas");
  if(canvases.length == 0) {
    return "";
  }
  const canvas = canvases[0];
  return canvas.toDataURL();
};

const Page = ({ location }) => {
  const [renderedTwice, setRenderedTwice] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const url = fromOrderIdToUrl(orderId);

  useEffect(() => {
    if(!renderedTwice) {
      setRenderedTwice(true);
    }
    if(orderId == null) {
      createOrderId(newOrderId => setOrderId(newOrderId));
    }
  }, [renderedTwice, orderId]);

  return (
    <Layout location={location} validate={false} showOrder={false}
      header="Please scan the QR code below."
    >
      <Label tag color={Color.accent}>{orderId}</Label>
      <br/><br/>
      <QRCode value={url} size={QR_SIZE}/>
      <br/><br/>
      <div className="super-centered">
        <Button color={Color.accent} basic onClick={() => {
          createOrderId(newOrderId => setOrderId(newOrderId));
        }}>Generate New Order</Button>
        <Button as="a" color={Color.accent} href={getCanvasUrl()} download={"qr-" + orderId + ".png"}>Download</Button>
      </div>
    </Layout>
  );
};

export default withAuthenticator(Page);
